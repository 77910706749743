import autosize from "autosize/dist/autosize";
import FormUpload from "./FormUpload";
export default class Form{
    constructor($main) {

        let me=this;
        this.$main=$main;
        this.apiAction=this.$main.attr("data-form")
        $main.on("submit",function(e){
            e.preventDefault();
            me.submit();
        });
        $main.on("change input","textarea,select,input",function(){
            me.checkFields();
        });

        $main.find("select[message-placeholder]").on("change",function(){
            me.refreshMessagePlaceholder();
        });

        $main.on("change",'[data-form-up-btn]',function(e){
            let upName=$(this).attr('data-form-up-btn');
            let $prog=$main.find(`[data-form-up-progress='${upName}']`);
            let $field=$main.find(`[data-form-up-field='${upName}']`);
            let $name=$main.find(`[data-form-up-name='${upName}']`);
            $prog.css("width","0%");
            $name.text($(this)[0].files[0].name);
            let file=$(this)[0].files[0];
            FormUpload.uploadChuncked(
                file,function(percent,b,c){
                    $prog.css("width",percent+"%");
                },function(data){
                    console.log("comp",data)
                    $prog.css("width","0%");
                    $field.val(data.json.filename);
                    me.checkFields();
                },function (e){
                    console.log("err",a)
                    $prog.css("width","0%");
                    $name.text("Il y a eu une erreur :(");
                }
            );
        })

        me.refreshMessagePlaceholder();

    }
    $fields(){
        return this.$main.find("input,textarea,select");
    }
    refreshMessagePlaceholder(){
        let $o=this.$main.find("[placeholder]:selected");
        if($o.length){
            let oVal=$o.attr("placeholder");
            this.$main.find("[name='message']").attr("placeholder",oVal);
        }
    }

    /**
     *
     * @returns {boolean}
     */
    checkFields(){
        let valid="valid";
        this.$fields().removeClass("error");
        this.$fields().closest(".field-wrap").removeClass("field-wrap-error");
        this.$fields().filter("[mandatory]").each(function(){
            let $f=$(this);
            if($f.val().length<2){
                valid="";
                $f.addClass("error")
                $f.closest(".field-wrap").addClass("field-wrap-error");
            }
            if($f.is("[type='checkbox']")){
                if(!$f.is(":checked")){
                    valid="";
                    $f.addClass("error")
                    $f.closest(".field-wrap").addClass("field-wrap-error");
                }
            }
        });

        this.$main.attr("state",valid);
        return valid!=="";
    }
    submit(){
        let me=this;
        if(this.checkFields()){

            me.$main.attr("state","sending");

            let formdata = me.$main.serializeArray();
            let data = {};
            $(formdata ).each(function(index, obj){
                data[obj.name] = obj.value;
            });
            PovApi.actionCB("api."+me.apiAction,data,function(res){
                console.log(res);
                if(res.success){
                    me.$main.addClass("send-success");
                    me.$main[0].reset();
                    me.$main.find("[data-form-up-name]").text("");
                    me.$main.attr("state","sent");
                    me.$main.find(".success-message").html(res.messages.join("<br>"))
                }else{
                    me.$main.attr("state","error");
                    me.$main.find(".error-message").html(res.errors.join("<br>"))
                }

            },function(){
                me.$main.attr("state","error");
                me.$main.find(".error-message").html("error");
            })


        }
    }



    static fromDom(){
        $("[data-form]").not(".form-init").each(function(){
            $(this).addClass("form-init");
            new Form($(this));
        });
    }

}





