export default class Affix{
    /**
     *
     * @param {JQuery} $main
     */
    constructor($main){
        let me =this;
        /**
         *
         * @type {JQuery}
         */
        this.$main=$main;

        /**
         *
         * @type {JQuery}
         */
        this.$affix=this.$main.find("[data-affix-element]");
        /**
         * Sera true si le scroll est sur l'objet
         * @type {boolean}
         */
        this.sceneActive=false;
        this.$main.on("SCENE_ENTER",function(e){
            e.stopPropagation();
            me.sceneActive=true;
            //console.log("enter......... js-affix");
        });
        this.$main.on("SCENE_LEAVE",function(e){
            e.stopPropagation();
            //console.log("leave......... js-affix");
            me.sceneActive=false;
            let $affix=$(this).find(".js-affix-menu");
            me.killAffix();
        });
        this.$main.on("SCENE_PROGRESS",function(e){
            e.stopPropagation();
            me.positionAffix();
        });



        let loop=setInterval(function(){
            if (!jQuery.contains(document, me.$main[0])) {
                clearInterval(loop);
            }else{
                me.positionAffix();
            }

        },10);

    }

    /**
     * place le menu en fixed (ou non) selon le cas
     */
    positionAffix(){
        let offset=this.$main.offset();
        let offsetTop=$("#main-navbar").height()+20;
        if(STAGE.width>=992){
            if(offset.top - offsetTop <= STAGE.scrollY && offset.top+this.$main.height()>STAGE.scrollY){

                let $affix=this.$affix;
                let basMain=this.$main.outerHeight() + this.$main.offset().top - STAGE.scrollY - offsetTop;
                let basAffix=this.$affix.height()+20;
                $affix.css("position","fixed");
                $affix.css("z-index","1000");
                $affix.css("top",offsetTop);
                $affix.css("left",$affix.parent().offset().left);
                $affix.css("width",$affix.parent().outerWidth() );
                if(basAffix>basMain){
                    $affix.css("top",(basMain-basAffix-20)+"px");
                }
            }else{
                this.killAffix();
            }
        }else{
            this.killAffix();
        }

    }

    /**
     * le menu n'est plus en fixed
     */
    killAffix(){
        this.$affix.css("position","");
        this.$affix.css("width","");
        this.$affix.css("left","");
    }

    /**
     * initialise tous les [data-affix-anchors]
     */
    static initFromDom(){
        $("[data-affix-container='']").each(function(){
            new Affix($(this));
            $(this).attr("data-affix-container","init-done");
        })
    }


}