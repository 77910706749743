export default class DataConcoursVoteToggle{

    static initListeners(){
        $body.on("click","[data-concours-vote-toggle]",function(e){
            e.preventDefault();
            let $clicked=$(this);
            let concoursUid=$clicked.attr("data-concours");
            let participationUid=$(this).attr("data-participation");
            let $oldVoted=$(`[data-concours-vote-toggle][data-concours='${concoursUid}'].has-voted`)
            if($oldVoted.length){
                $oldVoted.removeClass("has-voted");
                console.log("go -");
                DataConcoursVoteToggle._incrementVote($oldVoted,-1);
            }
            $clicked.addClass("has-voted");
            console.log("go +")
            DataConcoursVoteToggle._incrementVote($clicked,+1);
            let votedId=$clicked.attr("data-vote-toggle");
            DataConcoursVoteToggle._setLocalVotedId(concoursUid,participationUid)
            DataConcoursVoteToggle._vote(concoursUid,participationUid);
        })
        DataConcoursVoteToggle.refreshDisplay();
    }
    static _vote(concoursUid,participationUid){
        console.log("envoyer au serveur qu'on vote pour "
            +participationUid
            +" au concours "
            +concoursUid,
            DataConcoursVoteToggle._getUserLocalId()
        );
        DataConcoursVoteToggle.refreshDisplay();
        PovApi.actionCB("api.vote-concours",
            {
                "userid":DataConcoursVoteToggle._getUserLocalId(concoursUid),
                "participationUid":participationUid
            }
            ,function(res){
            console.log(res);
            if(res.success){
                //console.log("voted")
            }else{
                //console.log("err1")
            }

        },function(){
            //console.log("err2")
        })



    }
    static _incrementVote($el,howMany){
        let $num=$el.find("[data-votes-count]")
        let numVotes=$num.attr("data-votes-count")*1.0;
        console.log("inc",$el,howMany,"numVotes",numVotes)
        numVotes+=howMany;
        $num.attr("data-votes-count",numVotes);
        $num.text(""+numVotes+" vote"+(numVotes !==1 ? "s" : ""))
    }

    /**
     * Marque comme .has-voted les précédentes participations
     */
    static refreshDisplay(){
        //récuper les uids de concours possibles depuis le dom
        let $concours=$body.find(`[data-concours-vote-toggle][data-concours]`);
        let concoursUids=[];
        $concours.each(function(){
            let uid=$(this).attr("data-concours");
            if(concoursUids.indexOf(uid)===-1){
                concoursUids.push(uid);
            }
        })
        //applique la classe has voted là où l'utilisateur a voté
        for(let concoursUid of concoursUids){
            let localVotedId=DataConcoursVoteToggle._getLocalVotedId(concoursUid);
            if(localVotedId){
                $body.find(`[data-concours-vote-toggle][data-participation='${localVotedId}'][data-concours='${concoursUid}']`)
                    .addClass("has-voted");
            }
        }


    }

    /**
     * Renvoie et crée au besoin l'id utilisateur local pour le concours donné
     * @private
     * @return {string}
     */
    static _getUserLocalId(concoursUid){
        let userId=localStorage.getItem("concours-user-id-"+concoursUid);
        if(!userId){
            userId=""+Date.now()+"-a-"+concoursUid;
            localStorage.setItem("concours-user-id-"+concoursUid,userId);
        }
        return userId;
    }

    /**
     * Renvoie pour quelle participation dans ce concours l'utilisateur à déjà voté
     * @return {string}
     * @private
     */
    static _getLocalVotedId(concoursUid){
        return localStorage.getItem("concours-"+concoursUid);
    }

    /**
     * Définit en local pour quelle participation l'utilisateur a voté
     * @private
     * @param {string} concoursUid
     * @param {string} participationUid
     */
    static _setLocalVotedId(concoursUid,participationUid){
        return localStorage.setItem("concours-"+concoursUid,participationUid);
    }
}




