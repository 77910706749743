export default class FormUpload{
    /**
     * Upload un fichier en le découpant
     * @param {File} file
     * @param {function} cbProgress Renvoie la progression sous forme de : pourcentage, bytesuploadés, bytesTotaux
     * @param {function} cbComplete Renvoie un ApiReturn avec le fichier
     * @param {function} cbError Renvoie un ApiReturn avec les erreurs
     * @param {function} cbReader Renvoie le reader une fois qu'il est loadend, ce qui permet par exemple d'afficher une image de preview comme ceci $img.prop('src',reader.result)
     */
    static uploadChuncked(file,cbProgress,cbComplete,cbError,cbReader) {
        let uploadUrl = LayoutVars.rootUrl+"/povApi/action/api.upload";

        let upfileName=""+new Date().getTime()+"-"+file.name;
        let reader = new FileReader();
        reader.onloadend = function (evt) {
            let binary = evt.target.result;
            doUpload();
        };
        //reader pour une preview
        if (cbReader) {
            let readerDataUrl = new FileReader();
            readerDataUrl.onloadend = function (evt) {
                if (cbReader) {
                    cbReader(readerDataUrl);
                }
            };

            readerDataUrl.readAsDataURL(file);
        }


        //pour l'upload
        reader.readAsBinaryString(file);

        //-----------------2 l'upload---------------------------------

        function doUpload(){
            let size, filename, filenametmp;
            processFile();
            function processFile(e) {
                filename=file.name;
                filenametmp=""+new Date().getTime()+file.name;
                size = file.size;

                let sliceSize = 1048576; // 1MB chunk sizes.;
                if(size<1048576 * 5){
                    sliceSize=104857; // 100k chunk si moins de 5m
                }
                let start = 0;
                setTimeout(loop, 1);
                function loop() {
                    let end = start + sliceSize;
                    if (size - end < 0) {
                        end = size;
                    }
                    let s = slice(file, start, end);
                    sendChunck(s, start, end,sliceSize,function(json){
                        if (end < size) {
                            start += sliceSize;
                            setTimeout(function(){
                                loop();
                            },10);
                        }else{
                            cbComplete(json);
                        }
                    });
                }
            }

            function sendChunck(piece, start, end,sliceSize,cbNextChunk) {
                let formdata = new FormData();
                let xhr = new XMLHttpRequest();
                let apiurl=uploadUrl+"?";
                apiurl+="upfileName="+upfileName+"&";
                apiurl+="size="+size+"&";
                apiurl+="end="+end+"&";
                apiurl+="sliceSize="+sliceSize+"&";
                xhr.open('POST',apiurl,true);
                formdata.append('chunck', piece);
                xhr.addEventListener("error", function(){
                    alert("error upload 1");
                    console.error("error upload 1")
                    let json=JSON.parse(xhr.response);
                    cbError(json)
                }, false);
                xhr.onload = function(e) {
                    let percentage=Math.floor(100/size*end);
                    let json=JSON.parse(xhr.response);
                    console.log("upload recieve ",filename,""+percentage+"%",e);
                    cbProgress(percentage,end,size);
                    cbNextChunk(json);
                };
                xhr.send(formdata);
                console.log("upload send ",filename,""+Math.floor(100/size*start)+"%");
            }
            /**
             * Formalize file.slice
             */
            function slice(file, start, end) {
                let slice = file.mozSlice ? file.mozSlice :
                    file.webkitSlice ? file.webkitSlice :
                        file.slice ? file.slice : noop;

                return slice.bind(file)(start, end);
            }
            function noop() {}
        }


    }


}