export default class DataVoteToggle{


    static _vote(votedId){
        console.log("envoyer au serveur qu'on vote pour",votedId,DataVoteToggle._getUserLocalId())
        DataVoteToggle.refreshDisplay();
        PovApi.actionCB("api.vote-copho2020",
            {
                "userid":DataVoteToggle._getUserLocalId(),
                "votedId":votedId
            }
            ,function(res){
            console.log(res);
            if(res.success){
                //console.log("voted")
            }else{
                //console.log("err1")
            }

        },function(){
            //console.log("err2")
        })



    }
    static _incrementVote($el,howMany){
        let $num=$el.find("[data-votes-count]")
        let numVotes=$num.attr("data-votes-count")*1.0;
        console.log("inc",$el,howMany,"numVotes",numVotes)
        numVotes+=howMany;
        $num.attr("data-votes-count",numVotes);
        $num.text(""+numVotes+" vote"+(numVotes !==1 ? "s" : ""))
    }
    static initListeners(){
        $body.on("click","[data-vote-toggle]",function(e){

            e.preventDefault();


            let $oldVoted=$("[data-vote-toggle].has-voted")
            if($oldVoted.length){
                $oldVoted.removeClass("has-voted");
                console.log("go -")
                DataVoteToggle._incrementVote($oldVoted,-1);
            }

            let $clicked=$(this);
            $clicked.addClass("has-voted");
            console.log("go +")
            DataVoteToggle._incrementVote($clicked,+1);
            let votedId=$clicked.attr("data-vote-toggle");
            DataVoteToggle._setLocalVotedId(votedId)
            DataVoteToggle._vote(votedId);
        })
        DataVoteToggle.refreshDisplay();
    }
    static refreshDisplay(){
        let localVotedId=DataVoteToggle._getLocalVotedId();
        if(localVotedId){
            $body.find("[data-vote-toggle='"+localVotedId+"']").addClass("has-voted");
        }

    }

    /**
     * Renvoie et crée au besoin l'id utilisateur local
     * @private
     * @return {string}
     */
    static _getUserLocalId(){
        let userId=localStorage.getItem("copho2020");
        if(!userId){
            userId=""+Date.now()+"-a";
            localStorage.setItem("copho2020",userId);
        }
        return userId;
    }

    /**
     *
     * @return {string}
     * @private
     */
    static _getLocalVotedId(){
        return localStorage.getItem("copho2020Voted");
    }

    /**
     *
     * @param votedId
     * @private
     */
    static _setLocalVotedId(votedId){
        return localStorage.setItem("copho2020Voted",votedId);
    }
}




