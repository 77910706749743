


function checkCookieAccept(){
    let cookieAccepted = localStorage.getItem('cookieAccepted');
    let $cook=$(".cookies");
    if(!cookieAccepted){
        $cook.addClass("to-check");
        $cook.on("click","[href='#ok']",function(e){
            e.preventDefault();
            localStorage.setItem('cookieAccepted',"true");
            checkCookieAccept();
        })
    }else{
        $cook.removeClass("to-check");
    }
}

checkCookieAccept();
